import React from 'react';
import { 
    Highlight,
    Text,
    } from '@chakra-ui/react'

export function TextoWalletsMovil(){
    
    return(
        <> 
            {/* <Text fontSize={'sm'} p={5} >
                <Highlight query='construction' styles={{ px: '1', py: '1', bg: 'orange.400', color: 'white' }}>
                Section under construction for mobile devices.
                </Highlight>
            </Text> */}
            <Text fontSize={'sm'} p={5} maxW={'1000px'} minW='full'>
                This web application is built for you to organize and consult different wallets 
                in a comfortable way, it does not store any data outside your browser.
            </Text>
            
        </>
    )
}
