import React from 'react';
import { 
    Box,
    Flex, 
    HStack,  
    } from '@chakra-ui/react'

import { BrowserView, MobileView } from 'react-device-detect';

import { Conector } from './conector';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { LogoErgo } from './LogoErgo';
import { AgregaWallet } from './AgregaWallet';
import { BotonWallets } from './BotonWallets';
import { Buscador } from './Buscador';

export function Header(){
          
    return(
        <>  
            <Flex justifyContent="flex-end">

                <BrowserView>
                    <Box mt={5} width={'100%'}>
                        <HStack>
                            <LogoErgo />
                            <Conector />
                            <AgregaWallet />
                            <BotonWallets />
                            <Buscador/>
                            <ColorModeSwitcher />
                        </HStack>
                    </Box>
                </BrowserView>

                <MobileView>
                    <Box mt={2}>
                        <HStack>
                            <LogoErgo />
                            <AgregaWallet />
                            <BotonWallets />
                            <ColorModeSwitcher />
                        </HStack>
                    </Box>
                </MobileView>
                
            </Flex>
       
        </>
    )
}