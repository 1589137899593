import React, { useEffect, useState } from 'react';
import { 
    Badge,
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuGroup,
    MenuList,
    MenuItem,
    MenuDivider,
    Stack,
    Tag,
    TagLabel,
    Text,
    useToast,
    HStack,
    } from '@chakra-ui/react'

    import { Link, useLocation } from 'wouter'

let walletLocalstorage = []

export function BotonWallets(){

    const [abroMenu, setabroMenu] = useState(false)
    
    const [infoConsultaWallet, setInfoConsultaWallet] = useState('')
    const [sumarErgos, setsumarErgos] = useState(0)
    const [infosPrecio, setInfoPrecio] = useState('')
    const [multiplicaEUR, setmultiplicaEUR] = useState(0)
    const [multiplicaUSD, setmultiplicaUSD] = useState(0)

    const [location, setLocation] = useLocation(); 

    const toast = useToast()
    const id = 'test-toast'

    function vaciaStorage(){
        localStorage.removeItem('arrayWallets')
        setsumarErgos(0)
        setmultiplicaEUR(0)
        setmultiplicaUSD(0)
        setInfoConsultaWallet()
        setLocation(`/`)
        if (!toast.isActive(id)) { 
            toast({ 
            id,
            description: "Wallets successfully removed!",
            duration: 2000,
            isClosable: true,
            position: 'bottom',
            title: 'Address removed!',
            status: 'success',
            variant: 'solid'
            })
        }
    }

    useEffect(() => {
        const apiURLPrecio = `https://api.nanopool.org/v1/ergo/prices`
        fetch(apiURLPrecio)
            .then(res => res.json())
            .then(response => {
                const datos = response
                const precioEUR = datos.data.price_eur
                const precioUSD = datos.data.price_usd
                setInfoPrecio([precioEUR, precioUSD])
            })
    }, [])

    function activateButton(){
        setabroMenu(!abroMenu)
    }

    useEffect(() => {
        let sumaDeErgos = 0.0
        let arrayDeNanoErgs = []
        let arrayNuevoLocalstorage = []
        if (localStorage.getItem('arrayWallets')){
            // Utilizo la JSON.parse para transformar la cadena JSON en un objeto Javascript
            let walletLocalstorage = JSON.parse(localStorage.getItem('arrayWallets'))
    
            for (let i=0; i<walletLocalstorage.length; i++){
                fetch(`https://api.ergoplatform.com/api/v1/addresses/${walletLocalstorage[i].address}/balance/confirmed`)
                    .then(response => response.json())
                    .then(res => {
                        
                        const ergosEnWallet = (res.nanoErgs/1000000000).toFixed(2)
                        sumaDeErgos = parseFloat(sumaDeErgos) + parseFloat(ergosEnWallet)
                        const totalEUR = (infosPrecio[0] * sumaDeErgos).toFixed(2)
                        const totalUSD = (infosPrecio[1] * sumaDeErgos).toFixed(2)
                        setmultiplicaEUR(totalEUR)
                        setmultiplicaUSD(totalUSD)
                        
                        setsumarErgos(sumaDeErgos.toFixed(2))

                        // Creo el objeto con el valor real de ERG que hay en la cartera según la API
                        let objetoNuevoAddress = {}
                        objetoNuevoAddress.id = Date.now()
                        objetoNuevoAddress.name = walletLocalstorage[i].name
                        objetoNuevoAddress.address = walletLocalstorage[i].address
                        objetoNuevoAddress.ergos =  parseFloat(ergosEnWallet)

                        // Preparo el array de objetos con los datos correctos para sustituir el localstorage completo.
                        arrayNuevoLocalstorage.push(objetoNuevoAddress)
                        // Actualizo cada uno de los registros de localstorage con el objeto nuevo con los ERG actualizados.
                        localStorage.setItem('arrayWallets', JSON.stringify(arrayNuevoLocalstorage))

                    })
            } 
            walletLocalstorage = JSON.parse(localStorage.getItem('arrayWallets'))

            const listaWallet = walletLocalstorage.map(wallet =>
                <Link key={wallet.address} href={"/wallet/" + wallet.address}>
                    <MenuItem>
                        <Tag rounded={'full'} size={'md'} variant='outline' colorScheme='blue' mr={2}>
                            <TagLabel fontSize={'small'}>{wallet.ergos} Σ</TagLabel>
                        </Tag>
                        <Text fontSize={'small'}>{wallet.name}</Text>
                    </MenuItem>
                </Link> 
            ) 

            // Actualizo con los dos valores que he sacado, enviando un array
            setInfoConsultaWallet(listaWallet)
        } else {
            // Actualizo con los dos valores que he sacado, enviando un array
            setInfoConsultaWallet(['No hay wallets', '']) 
        }
    }, [abroMenu])
          
    return(
        <>  
        <Box>
            <Menu >
                <MenuButton as={Button} rounded={'full'} m={0} onClick={activateButton}>
                    Wallets
                </MenuButton>
                <MenuList rounded={'null'} pl={2} pr={2}>
                    <MenuGroup title='Wallets'>
                        <Text ml={4}>{infoConsultaWallet}</Text>
                    </MenuGroup>
                <MenuDivider />
                <MenuGroup title='Total in Wallets'>
                        <Stack direction='row' mr={'10px'}>
                            <Badge rounded={'full'} p={2} ml={4} variant='solid' color='cyan.300'>
                                {sumarErgos} Σ
                            </Badge>
                            <Badge rounded={'full'} p={2} ml={4} variant='solid' color='cyan.300'>
                                {multiplicaEUR} €
                            </Badge>
                            <Badge rounded={'full'} p={2} ml={4} variant='solid' color='cyan.300'>
                                {multiplicaUSD} $
                            </Badge>
                        </Stack>
                        <Box mt={6} mb={2} mr={3} textAlign={'end'}>
                            <Button 
                            size={'xs'} 
                            onClick={vaciaStorage} 
                            color='red' 
                            rounded={'full'} >Clean wallets</Button>
                        </Box>
                </MenuGroup>
                </MenuList>
            </Menu>
        </Box>
        </>
    )
}