import React, { useEffect, useState } from 'react';
import { 
    Container,
    } from '@chakra-ui/react'

import { BrowserView, MobileView, isBrowser, isMobileOnly, isTablet, isDesktop } from 'react-device-detect';

import { TableWallets } from '../components/general/TableWallets';
import { TextoWallets } from '../components/general/TextoWallets';
import { TextoWalletsMovil } from '../components/general/TextoWalletsMovil';
import { InfoGeneral } from '../components/header/InfoGeneral';

export function Home({wallet}){

    const [infoDevice, setInfoDevice] = useState('') 

    useEffect(() => {
        if(isMobileOnly){
            setInfoDevice(
                <>
                    <InfoGeneral />
                    <TextoWalletsMovil />
                </>
            )
        } else if (isTablet) {
            setInfoDevice(
                <>
                    <InfoGeneral />
                    <TableWallets />
                    <TextoWallets />
                </>
            )
        } else if (isDesktop) {
            setInfoDevice(
                <>
                    <InfoGeneral />
                    <TableWallets />
                    <TextoWallets />
                </>
            )
    
        } 
    }, [])

    

    return(
        <>

        {infoDevice}

        {/* <BrowserView>
            <TextoWallets />
            <TableWallets />
        </BrowserView>
        
        <MobileView>
            <TextoWalletsMovil />
        </MobileView> */}
        </>
    )
}
