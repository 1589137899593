import React from 'react';
import {
    Button,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
  } from '@chakra-ui/react'

import QRCode from "react-qr-code";

import { ImQrcode } from "react-icons/im";

export function ModalQR({ wallet }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
      <>
       
            <ImQrcode onClick={onOpen} />
       
  
        <Modal onClose={onClose} isOpen={isOpen} ml={2}>
          <ModalOverlay />
          <ModalContent rounded={'none'} ml={5} mr={5}>
            <ModalHeader>Wallet QR</ModalHeader>
            <ModalCloseButton rounded={'none'}/>
            <ModalBody>
                
            <Center>
                <QRCode value={wallet} />
            </Center>
                                
            </ModalBody>
            <ModalFooter>
              ErgoWallets.org
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }