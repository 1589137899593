import React, { setState }  from 'react'
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    Input,
    Stack,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { useLocation } from "wouter";
import { AddIcon } from '@chakra-ui/icons'

import { Form, Formik, Field } from 'formik';

let arrayWallets = []
let walletLocalstorage = []
let infoWallet = {}

export function AgregaWallet(){

    const toast = useToast()
    const id = 'test-toast'

    const [location, setLocation] = useLocation();

    const { isOpen, onOpen, onClose } = useDisclosure()
    const firstField = React.useRef()
    
    function validateAddress(value) {
        let error
        if (value.address == '' || value.name == '') {
            if (!toast.isActive(id)) {
                toast({ 
                id,
                description: "Name and address are required!",
                duration: 2000,
                isClosable: true,
                position: 'top-left',
                title: 'Required!',
                status: 'warning',
                variant: 'solid'
                })
            }
        } else {
            if (typeof(Storage) === "undefined") {
                if (!toast.isActive(id)) {
                    toast({ 
                    id,
                    description: "Localstorage not available!",
                    duration: 2000,
                    isClosable: true,
                    position: 'top-left',
                    title: 'Localstorage!',
                    status: 'success',
                    variant: 'solid'
                    })
                }
            } else {
                const loadWallet = async() => {
                    const url = `https://api.ergoplatform.com/api/v1/addresses/${value.address}/balance/confirmed`
                    const res = await fetch(url)
                    const data = await res.json()
                    
                    if (res.status == 200){
                        infoWallet.id = Date.now()
                        infoWallet.name = value.name
                        infoWallet.address = value.address
                        infoWallet.ergos = (data.nanoErgs/1000000000).toFixed(2)
    
                        if (localStorage.getItem('arrayWallets')){
                            // Si ya existen wallets en localstore voy a comprobar que la nueva no esté
                            // para no duplicar ninguna existente y para no perder las que ya existen.
                            walletLocalstorage = JSON.parse(localStorage.getItem('arrayWallets'))
                            // Guardo en el array las que ya existen en localstorage más la nueva.
                            arrayWallets = [...walletLocalstorage, infoWallet]
                            let existe = false
                            for (let i=0; i<walletLocalstorage.length; i++){
//////////////////////////////// Existe localstorage y la wallet
                                if (walletLocalstorage[i].address == value.address){
                                    existe = true
                                    if (!toast.isActive(id)) {
                                        toast({ 
                                        id,
                                        description: "This address already exists!",
                                        duration: 2000,
                                        isClosable: true,
                                        position: 'top-left',
                                        title: 'Address exists!',
                                        status: 'error',
                                        variant: 'solid'
                                        })
                                    }
                                }
                            }
//////////////////////////////// Existe localstorage pero no la wallet
                            if (existe == false) {
                                // Guardo en el array las que ya existen en localstorage más la nueva.
                                arrayWallets = [...walletLocalstorage, infoWallet]
                                infoWallet = {id: '', name: '', address: '', ergos: ''}
                                localStorage.setItem('arrayWallets', JSON.stringify(arrayWallets))
                                if (!toast.isActive(id)) {
                                    toast({ 
                                    id,
                                    description: "Address correctly added!",
                                    duration: 2000,
                                    isClosable: true,
                                    position: 'top-left',
                                    title: 'Address added!',
                                    status: 'success',
                                    variant: 'solid'
                                    })
                                }
                                setLocation(`/wallet/${value.address}`)

                            }
                            

                            if (!toast.isActive(id)) {
                                toast({ 
                                id,
                                description: "Wallets successfully add!",
                                duration: 2000,
                                isClosable: true,
                                position: 'top-left',
                                title: 'Address add!',
                                status: 'success',
                                variant: 'solid'
                                })
                            }
                            setLocation(`/wallet/${value.address}`)
//////////////////////////////////////////////// No hay nada guardado en localstorage
                        } else {
                            // Guardo en el array las que ya existen en localstorage más la nueva.
                            arrayWallets.push(infoWallet)
                            infoWallet = {id: '', name: '', address: '', ergos: ''}
                            localStorage.setItem('arrayWallets', JSON.stringify(arrayWallets))
                            if (!toast.isActive(id)) {
                                toast({ 
                                id,
                                description: "Wallets successfully add!",
                                duration: 2000,
                                isClosable: true,
                                position: 'top-left',
                                title: 'Address add!',
                                status: 'success',
                                variant: 'solid'
                                })
                            }
                            setLocation(`/wallet/${value.address}`)
                        }
                    }else{
//////////////////////////////////////////////// Wallet incorrecta
                        if (!toast.isActive(id)) {
                            toast({ 
                            id,
                            description: "Address incorrect!",
                            duration: 2000,
                            isClosable: true,
                            position: 'top-left',
                            title: 'Address incorrect!',
                            status: 'error',
                            variant: 'solid'
                            })
                        }
                    }
                }
                loadWallet()
            }
        }
        return error
    }
  
    return (
      <>
        <Button _hover={{ bg: 'cyan.700' }} onClick={onOpen} bg={'cyan.400'} color={'cyan.100'} rounded={'full'}  mb={4} mt={3}>
          <AddIcon boxSize={'0.5em'} color='green.900' rounded={'full'} />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='right'
          initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px'>
              Add new address
            </DrawerHeader>
            <DrawerBody>
              <Stack spacing='24px'>
                <Formik
                    initialValues={{ address: '', name: '' }}
                    onSubmit={async (values, actions) => {
                        await new Promise((r) => setTimeout(r, 1000))
                        setTimeout(() => {
                            actions.setSubmitting(false)
                        }, 1000)
                        onClose()
                        validateAddress(values)
                    }}
                    >
                    {(props) => (
                        <Form>
                        
                        <Box pt={5}>
                        <VStack>
                            <Field name='name'>
                                {({ field, form }) => (
                                    <FormControl >
                                        <Input ref={firstField} {...field} placeholder='Enter address name' rounded={'full'} mt={3}/>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='address'>
                                {({ field, form }) => (
                                    <FormControl>
                                        <Input {...field} placeholder='Enter your address' rounded={'full'} mb={5} mt={1}/>
                                    </FormControl>
                                )}
                            </Field>
                            <Button 
                                width={'full'} 
                                bg={'cyan.400'} 
                                type='submit' 
                                rounded={'full'} 
                                isLoading={props.isSubmitting} >
                                Accept
                            </Button>
                        </VStack>
                        </Box>

                    </Form>
                    )}
                </Formik>
                </Stack>
            </DrawerBody>
            <DrawerFooter borderTopWidth='1px' pt={2} pb={2}>
                <Text fontSize='xs'>ErgoWallets.org</Text>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
}