import React, { useEffect, useState } from 'react'
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Text,
    HStack,
} from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons'

const apiURLPrecio = `https://api.nanopool.org/v1/ergo/prices`
const apiURLHight = `https://api.ergoplatform.com/api/v1/networkState`
const apiInfoHash = `https://api.ergoplatform.com/api/v0/info`


export function InfoGeneral(){

    const [infosErgoHeight, setInfoHeight] = useState('')
    const [infosPrecio, setInfoPrecio] = useState('')
    const [infosHash, setInfoHash] = useState('')

    useEffect(() => {
            fetch(apiURLHight)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    setInfoHeight(datos.height)
                })
            fetch(apiURLPrecio)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    let objetoPrecio = {
                        euros: datos.data.price_eur,
                        dolares: datos.data.price_usd
                    }
                    setInfoPrecio(objetoPrecio)
                })
            fetch(apiInfoHash)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    let objetoInfo = {
                        hashRate: (datos.hashRate/1000000000000).toFixed(2),
                        supply: (datos.supply/1000000000)
                    }
                    setInfoHash(objetoInfo)
                })
        
    }, [])

    return(
        <> 
            <Stat pl={5}>
                <HStack>
                    <StatLabel fontSize={'xs'} >
                        <HStack>
                        <Text>Height</Text>
                        <Text>{infosErgoHeight}</Text>
                        </HStack>
                    </StatLabel>
                    </HStack>
                    <HStack>
                    <StatLabel fontSize={'xs'}>
                        <HStack>
                            <Text>Hashrate:</Text>
                            <Text color={'orange'}>{infosHash.hashRate} TH/s </Text>
                        </HStack>
                    </StatLabel>
                </HStack>
                <StatLabel fontSize={'xs'}>
                    <HStack>
                        <Text>Supply:</Text>
                        <Text color={'blue.300'} >{infosHash.supply}</Text>
                        <Text><ArrowForwardIcon mr={2}/>97739924 ERG</Text>
                    </HStack>
                </StatLabel>
                <StatNumber>{infosPrecio.euros}€ {infosPrecio.dolares}$</StatNumber>
                
            </Stat>
        </>
    )
}