import React from 'react';
import { 
    Box,
    Center,
    Divider,
    Heading,
    Link,
    Table,
    Tag,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Text,
    Tooltip,
    HStack,
    useColorModeValue,
    } from '@chakra-ui/react'

import { CheckCircleIcon, TimeIcon, NotAllowedIcon } from '@chakra-ui/icons'
import { MdDesktopMac, MdWeb, MdStayPrimaryPortrait, MdFormatShapes, MdImportantDevices } from "react-icons/md";
import { FaAppStoreIos, FaGooglePlay } from "react-icons/fa";

export function TableWallets(){

    const bg = useColorModeValue('orange.200', 'gray.700')
    const color = useColorModeValue('green.400', 'green.400')
    const colorAzul = useColorModeValue('cyan.700', 'blue.300')

    const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
        <Box p='1'>
          <Tag ref={ref} {...rest}>
            {children}
          </Tag>
        </Box>
      ))
    
    return(
        <> 
        <Center>
            <Divider mt={4} />
        </Center>
        <Text fontSize={'sm'} p={3} maxW={'1000px'} >
                This web application is built for you to organize and consult different wallets 
                in a comfortable way, it does not store any data outside your browser.
            </Text>
        <Heading pl={4}>WALLETS</Heading>
            <TableContainer p={3} >
                <Table size='sm' variant='striped' colorScheme='gray'>
                    <TableCaption>
                        Digital wallets can help keep your digital currency safe, private and in your control.
                        <br /> I recommend that you always download from official sources ♡
                    </TableCaption>
                    
                    <Thead>
                        <Tr>
                            <Th color={'orange'}>Team</Th>
                            <Th>Type</Th>
                            <Th><Center>Ledger</Center></Th>
                            <Th><Center>Conn</Center></Th>
                            <Th><Center>ErgoPay</Center></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td><Link href='https://github.com/ergoplatform/ergo/releases/' isExternal textDecoration={'underline'}>Σ Node</Link></Td>
                            <Td>
                                <Tooltip label='Desktop'>
                                    <CustomCard variant={'ghost'}><MdDesktopMac /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Link href='https://play.google.com/store/apps/details?id=org.ergoplatform.android' isExternal textDecoration={'underline'}>
                                    <HStack>
                                        <Text>Σ Mobile Android </Text>
                                        <FaGooglePlay />
                                    </HStack>
                                </Link>
                                <Link href='https://apps.apple.com/bg/app/terminus-wallet-ergo/id1643137927' isExternal textDecoration={'underline'}>
                                    <HStack pt={2}>
                                        <Text>Σ Mobile iOS </Text>
                                        <FaAppStoreIos />
                                    </HStack>
                                </Link>
                            </Td>
                            <Td>
                                <Tooltip label='Mobile'>
                                    <CustomCard variant={'ghost'}><MdStayPrimaryPortrait /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><TimeIcon color={colorAzul} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><CheckCircleIcon color={color} /></Center></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Link href='https://ergopaperwallet.org/' isExternal textDecoration={'underline'}>
                                    Σ Paper
                                </Link>
                            </Td>
                            <Td>
                                <Tooltip label='Paper'>
                                    <CustomCard variant={'ghost'}><MdFormatShapes /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                        </Tr>
                    </Tbody>
                    <Thead>
                        <Tr>
                        <Th color={'orange'} py={3}>OTHER</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                            <Link href='https://github.com/minotaur-ergo/minotaur-wallet' isExternal textDecoration={'underline'}>
                                Minotaur
                            </Link>
                            </Td>
                            <Td>
                                <HStack>
                                    <Tooltip label='Multi plataforms'>
                                        <CustomCard variant={'ghost'}><MdImportantDevices /></CustomCard>
                                    </Tooltip>
                                </HStack>
                            </Td>
                            <Td><Center><TimeIcon color={colorAzul} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><CheckCircleIcon color={color} /></Center></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Link href='https://github.com/capt-nemo429/nautilus-wallet' isExternal textDecoration={'underline'}>
                                Nautilus
                                </Link>
                            </Td>
                            <Td>
                                <Tooltip label='Web'>
                                    <CustomCard variant={'ghost'}><MdWeb /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><TimeIcon color={colorAzul} /></Center></Td>
                            <Td><Center><CheckCircleIcon color={color} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Link href='https://satergo.com/' isExternal textDecoration={'underline'}>
                                Satergo
                                </Link>
                            </Td>
                            <Td>
                                <Tooltip label='Desktop'>
                                    <CustomCard variant={'ghost'}><MdDesktopMac /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><TimeIcon color={colorAzul} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                            <Td><Center><NotAllowedIcon color={'black.400'} /></Center></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Link href='https://github.com/ThierryM1212/SAFEW' isExternal textDecoration={'underline'}>
                                SAFEW
                                </Link>
                            </Td>
                            <Td>
                                <Tooltip label='Web'>
                                    <CustomCard variant={'ghost'}><MdWeb /></CustomCard>
                                </Tooltip>
                            </Td>
                            <Td><Center><TimeIcon color={colorAzul} /></Center></Td>
                            <Td><Center><CheckCircleIcon color={color} /></Center></Td>
                            <Td><Center><CheckCircleIcon color={color} /></Center></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}
