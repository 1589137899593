import React from 'react';
import { Switch, Route } from 'wouter'

import { Home } from '../pages/Home'
import { LeerWallet } from '../components/tokenlist/LeerWallet';
import { NoExiste } from '../pages/NoExiste'

export function MenuSwitch(){
    return(
    <>
        <Switch>
            <Route exact  path="/" component={Home} />
            <Route exact path="/wallet/:wallet" >
                {params => <LeerWallet wallet={params.wallet} />}
            </Route>
            <Route exact component={NoExiste} />
        </Switch>
    </>
    )
}