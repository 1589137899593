import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Highlight,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useColorModeValue,
    useDisclosure,
    useToast,
    Wrap,
    WrapItem
  } from '@chakra-ui/react'

export function ModalToken({ name, urlImage }) {

    const bg = useColorModeValue('gray.500', 'gray.500')
    const color = useColorModeValue('green', 'red')

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [infoCopy, setInfoCopy] = useState('false')
    const toast = useToast()
    const id = 'test-toast-id'

    const [tipoDeToken, setTipoDeToken] = useState()

    useEffect(() => {
        if (urlImage != ''){
            setTipoDeToken(
              <Image src={urlImage} />
            )
        } else {
            setTipoDeToken(
              <Highlight query='Not an NFT!' styles={{ px: '10', py: '2', bg: 'orange.100' }}>
                Not an NFT!
              </Highlight>
            )
        }
    }, [])

    useEffect(()=> {
        if (infoCopy === true){
            if (!toast.isActive(id)) {
                toast({ 
                    id,
                description: 'Id copied!',
                duration: 1000,
                isClosable: true,
                position: 'top',
                title: 'Id copied!',
                status: 'success',
                variant: 'solid'
            })
        }
        setInfoCopy(false)
    }
}, [infoCopy])

    return (
      <>
          <Wrap >
            <WrapItem>
              <Avatar 
                as={'button'}
                onClick={onOpen} 
                name={name} 
                src={urlImage} 
                showBorder={true}
                border={'2px'}
                bg={bg}
                />
            </WrapItem>
          </Wrap>
  
        <Modal onClose={onClose} isOpen={isOpen} ml={2}>
          <ModalOverlay />
          <ModalContent rounded={'none'} ml={5} mr={5}>
            <ModalHeader fontSize={'md'}>{name}</ModalHeader>
            <ModalCloseButton rounded={'none'} />
            <ModalBody>
              {tipoDeToken}   
            </ModalBody>
            <ModalFooter fontSize={'md'}>
              ErgoWallets.org
            </ModalFooter>
          </ModalContent>
        </Modal>
       
      </>
    )
  }