import React, { useEffect} from 'react';
import { 
    useToast,
    Heading
} from '@chakra-ui/react';


export function NoExiste(){

    const toast = useToast()
    const id = 'toast-404'
    
    useEffect(() => {

        if (!toast.isActive(id)) {
            toast({ 
                id,
                description: "The page you requested does not exist.",
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                title: 'Error 404',
                status: 'error',
                variant: 'solid'
            })
        }

    }, [])

    return(
        <>
            <Heading mt={10}>404 The page you requested does not exist!</Heading>
        </>
    )
}
