import React, { useEffect, useState } from 'react';
import {
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
    useToast,
    HStack
  } from '@chakra-ui/react'

import { MdThumbUp } from "react-icons/md";
import { CopyIcon } from '@chakra-ui/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { truncateString } from '../../functions/Functions';

import QR from '../../assets/QR-donate-9ggm3WB6e2BMbbCD3C9PJcgm5wbvmvCUG7w5rgszbKVWrVr8fSE.png'

export function ModalDonate() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [infoCopy, setInfoCopy] = useState('false')
    const toast = useToast()
    const id = 'test-toast'

    useEffect(()=> {
      if (infoCopy === true){
          if (!toast.isActive(id)) {
              toast({ 
              id,
              description: 'Wallet copied!',
              duration: 1000,
              isClosable: true,
              position: 'top-left',
              title: 'Wallet copied!',
              status: 'success',
              variant: 'solid'
              })
          }
          setInfoCopy(false)
      }
  }, [infoCopy])
    return (
      <>
        <Button 
            fontSize={'xs'}
            p={0}
            mb={1}
            onClick={onOpen} 
            rounded={'full'} 
            variant={'ghost'}
            _hover={{ bg: 'cyan.400' }} >
            <MdThumbUp />
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent ml={5} mr={5} rounded={'none'}>
            <ModalHeader>Donate ERG</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            Hi, I am LADOPIXΣL, developer of this website!
            If you liked the website you can invite me to a coffee, I will be happy to drink it to your health.
            
            <HStack mt={5}>
              <Text color={'cyan.400'}>{truncateString('9ggm3WB6e2BMbbCD3C9PJcgm5wbvmvCUG7w5rgszbKVWrVr8fSE', 15, '...')}</Text>
              <CopyToClipboard text={'9ggm3WB6e2BMbbCD3C9PJcgm5wbvmvCUG7w5rgszbKVWrVr8fSE'} onCopy={() => setInfoCopy(true)}>
                  <Button variant={'ghost'}> <CopyIcon /></Button>
              </CopyToClipboard>
            </HStack>
            <Image alt={'QR address Ergo for donation'} src={QR} width={'150px'} mt={5} mb={5}/>
            </ModalBody>
            <ModalFooter>
              ErgoWallets.org
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }