import React, { useEffect, useState } from 'react';
import { 
    Avatar,
    Box,
    Button,
    HStack,
    Link,
    SkeletonCircle,
    SkeletonText,
    Stat,
    StatLabel,
    Text,
    useColorModeValue,
    useToast,
    Wrap,
    WrapItem,
    } from '@chakra-ui/react'

import { GoVerified, GoClock } from "react-icons/go";
import { ImClock2 } from "react-icons/im";
import { CopyIcon } from '@chakra-ui/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { BrowserView, MobileView } from 'react-device-detect';
import { toUtf8String, resolveIpfs, truncateString } from '../../functions/Functions';

import { ModalToken } from './ModalToken'

export function Listado({wallet}){

    const [infoTokens, setInfoTokens] = useState('')
    const [skeleton, setSkeleton] = useState('')

    const [infoCopy, setInfoCopy] = useState('false')
    const toast = useToast()
    const id = 'test-toast-id'

    const bg = useColorModeValue('gray.200', 'gray.700')
    const color = useColorModeValue('gray.800', 'gray.100')

    useEffect(()=> {
        if (infoCopy === true){
            if (!toast.isActive(id)) {
                toast({ 
                id,
                description: 'Id copied!',
                duration: 1000,
                isClosable: true,
                position: 'top',
                title: 'Id copied!',
                status: 'success',
                variant: 'solid'
                })
            }
            setInfoCopy(false)
        }
    }, [infoCopy])

    useEffect(() => {
        let objetoToken = {}
        let arrayTokens = []
        setInfoTokens()
        setSkeleton(
            <Box p={2} pl={4} pt={4} boxShadow='2xl'>
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
            </Box>
        )
        const loadWallet = async() => {
            const res = await fetch(`https://api.ergoplatform.com/api/v1/addresses/${wallet}/balance/confirmed`)
            const data = await res.json()
            if (res.ok){
                if (data.tokens.length){
                    arrayTokens = []
                    for (let i = 0; i < data.tokens.length; i++) {
                        const res2 = await fetch(`https://api.ergoplatform.com/api/v0/assets/${data.tokens[i].tokenId}/issuingBox`)
                        const data2 = await res2.json()
                        if (res2.ok){
                            objetoToken = {
                                tokenCreationHeight: data2[0].creationHeight,
                                tokenId: data2[0].assets[0].tokenId,
                                tokenAmount: data.tokens[i].amount,
                                tokenAmountEmit: data2[0].assets[0].amount,
                                tokenName: data2[0].assets[0].name,
                                tokenDecimals: data2[0].assets[0].decimals,
                                tokenType: data2[0].assets[0].type,
                                tokenDescription: toUtf8String(data2[0].additionalRegisters.R5).substring(2),
                                tokenR9: resolveIpfs(toUtf8String(data2[0].additionalRegisters.R9).substring(2)),
                                tokenTransactionConf: 'https://explorer.ergoplatform.com/en/transactions/' + data2[0].spentTransactionId
                            }
                        }
                        arrayTokens[i] = objetoToken
                    }
                    const listaWallet = arrayTokens.map(token =>
                        <Box key={token.tokenId} shadow={'2xl'} mt={2} mb={2} p={2}>
                            <Box p={2} pl={2} pt={4}>
                                <HStack>
                                    <ModalToken 
                                        name={token.tokenName}
                                        urlImage={token.tokenR9}
                                    /> 
                                    <Text fontWeight={'bold'}>{truncateString(token.tokenName, 20, '...')}</Text>
                                </HStack>
                            </Box>
                            <Box pl={2} pb={2}>
                                <Text >{token.tokenAmount}/{token.tokenAmountEmit}</Text>
                                <HStack mt={2}>
                                    <ImClock2 />
                                    <Text fontWeight={'bold'}>Creation Height:</Text> 
                                    <Text>{token.tokenCreationHeight}</Text>
                                </HStack>
                                <HStack >
                                    <GoVerified fontSize={'md'}/>
                                    <Text fontWeight={'bold'}>Id:</Text>
                                    <BrowserView>
                                        <Text>{truncateString(token.tokenId, 25, '...')}</Text>
                                    </BrowserView>
                                    <MobileView>
                                        <Text>{truncateString(token.tokenId, 12, '...')}</Text>                                    
                                    </MobileView>
                                    <CopyToClipboard text={token.tokenId} onCopy={() => setInfoCopy(true)}>
                                        <button> <CopyIcon /></button>
                                    </CopyToClipboard>
                                </HStack>
                                <BrowserView>
                                    <Link 
                                        href={'https://ergotokens.org/#/?token=' + token.tokenId} 
                                        isExternal
                                        style={{
                                            textDecoration: 'none',
                                        }} >
                                        <Button 
                                            size={'xs'} 
                                            fontSize={'small'} 
                                            color={'gray.100'} 
                                            bg={'gray.400'}
                                            rounded={'full'} 
                                            _hover={{ bg: 'cyan.400' }}
                                            mt={3} >Details in ErgoTokens.org </Button>
                                    </Link>
                                </BrowserView>
                                <MobileView>
                                    <Link href={'https://ergotokens.org/#/?token=' + token.tokenId} 
                                        isExternal
                                        style={{
                                            textDecoration: 'none',
                                        }} >
                                        <Button 
                                            size={'xs'} 
                                            fontSize={'small'} 
                                            color={'gray.100'} 
                                            bg={'gray.400'}
                                            rounded={'full'} 
                                            _hover={{ bg: 'cyan.400' }}
                                            mt={3} >Details in ErgoTokens.org </Button>
                                    </Link>
                                </MobileView>

                            </Box>
                        </Box>
                            
                    )
                    setSkeleton()
                    setInfoTokens(listaWallet)
                } else {
                    if (!toast.isActive(id)) {
                        toast({ 
                        id,
                        description: 'No tokens!',
                        duration: 2000,
                        isClosable: true,
                        position: 'top',
                        title: 'No tokens!',
                        status: 'error',
                        variant: 'solid'
                        })
                    }
                    setSkeleton()
                }
            }
        }
        loadWallet()
    },[wallet])

    return(
        <> 
            <Box p={5} maxW={'1000px'} minW='full'>
                <Stat>
                    <StatLabel>Token list</StatLabel>
                    {skeleton}
                    {infoTokens}
                </Stat>
            </Box>
           
        </>
    )
}
