import React from 'react';
import { 
    Text,
    } from '@chakra-ui/react'

export function TextoWallets(){
    
    return(
        <> 
            <Text fontSize={'sm'} p={3} maxW={'1000px'} mb={5}>
                * Note: that wallets are supported by third parties, 
                we only provide the information below as a courtesy, 
                in order to simplify the overall digital currency experience for you.
            </Text>
        </>
    )
}
