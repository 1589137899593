import React from 'react';
import {
  } from '@chakra-ui/react';

import { MenuSwitch } from './MenuSwitch';

export function Inicio(){

    return(
        <>  
            <MenuSwitch />
        </>
    )
}