import React, { useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { Link } from 'wouter'

import {
    Button,
    FormControl,
    HStack,
    Input,
    useToast,
  } from '@chakra-ui/react'
  
let wallets = []
let walletAlmacenadas = []
let infoWallet = {id: '', name: '', address: ''}



export function Buscador() {

    const toast = useToast()
    const id = 'test-toast'

    const [urlWallet, seturlWallet] = useState('')
    const [esHidden, setEsHidden] = useState(true)

    function validateAddress(value) {
        let error
        if (!value) {
            setEsHidden(true)
        } else {
            try {
                const loadWallet = async() => {
                    const url = `https://api.ergoplatform.com/api/v1/addresses/${value}/balance/confirmed`
                    const res = await fetch(url)
                    const data = await res.json()
                    if (res.status == 200){
                        infoWallet.id = Date.now()
                        infoWallet.address = value
                        infoWallet.ergos = (data.nanoErgs/1000000000).toFixed(2)
                        wallets = [...wallets, infoWallet]
			            infoWallet = {id: '', name: '', address: '', ergos: ''}
			            
                        if (!toast.isActive(id)) {
                            toast({ 
                            id,
                            description: "Address correctly.",
                            duration: 2000,
                            isClosable: true,
                            position: 'top-left',
                            title: 'Address ok!',
                            status: 'success',
                            variant: 'solid'
                            })
                        }
                        seturlWallet('/wallet/' + infoWallet.address)
                        setEsHidden(false)
                    } else {
                        if (!toast.isActive(id)) {
                            toast({ 
                            id,
                            description: "Address incorrect.",
                            duration: 2000,
                            isClosable: true,
                            position: 'top-left',
                            title: 'Address incorrect!',
                            status: 'error',
                            variant: 'solid'
                            })
                        }
                    }
                }
                loadWallet()
                seturlWallet('/wallet/' + value)
            } catch (err) {
                console.log(err);
            }
        }
        return error
    }
  
    return (
        
        <HStack>
            

            <Formik
            initialValues={{ address: '' }}
            onSubmit={(values, actions) => {
                setTimeout(() => {
                    actions.setSubmitting(false)
                }, 1000)
            }}
            >
            {(props) => (
            <Form>
                <Field name='address' validate={validateAddress}>
                {({ field, form }) => (
                    <FormControl isInvalid={form.errors.address}>
                        <Input fontSize={'small'} {...field} placeholder='Enter your address' rounded={'full'} />
                    </FormControl>
                )}
                </Field>
            </Form>
            
            )}
        </Formik>
        <Link href={urlWallet}>
                <Button hidden={esHidden} rounded={'full'}>Ok</Button>
            </Link>
        </HStack>
    )
  }