import React, { useEffect, useState } from 'react';
import { 
    Badge,
    Box,
    Button,
    HStack,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Text,
    useToast,
    } from '@chakra-ui/react'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { CopyIcon } from '@chakra-ui/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { truncateString } from '../../functions/Functions';
import { ModalQR } from './ModalQR';

export function Header({wallet}){

    const [totalERG, setTotalERG] = useState('')
    const [totalTokens, setTotalTokens] = useState('')
    
    const [infoCopy, setInfoCopy] = useState('false')
    const toast = useToast()
    const id = 'test-toast'

    useEffect(()=> {
        if (infoCopy === true){
            if (!toast.isActive(id)) {
                toast({ 
                id,
                description: 'Wallet copied!',
                duration: 1000,
                isClosable: true,
                position: 'top',
                title: 'Wallet copied!',
                status: 'success',
                variant: 'solid'
                })
            }
            setInfoCopy(false)
        }
    }, [infoCopy])
    
    useEffect(() => {
        const loadWallet = async() => {
            const res = await fetch(`https://api.ergoplatform.com/api/v1/addresses/${wallet}/balance/confirmed`)
            const data = await res.json()
            if (res.ok){
                setTotalERG((data.nanoErgs/1000000000).toFixed(2))
                setTotalTokens(data.tokens.length)
            }
        }

        loadWallet()
    },[wallet])

    return(
        <> 
        
            <Box pl={'10'} pt={'2'} maxW={'1000px'} minW={'100%'}>
                <Stat minW={'full'} >
                    <HStack>
                        <ModalQR wallet={wallet}/>
                        <StatLabel>Address info</StatLabel>
                    </HStack>
                    <BrowserView>
                        <HStack>
                            <StatNumber>{totalERG} Σ</StatNumber>
                            <Stack direction='row'>
                            <Badge colorScheme='purple' rounded={'none'}>{totalTokens} tokens</Badge>
                            </Stack>
                            <Text fontSize={'small'}>{truncateString(wallet, 20, '...')}</Text>
                            <CopyToClipboard text={wallet} onCopy={() => setInfoCopy(true)}>
                                <CopyIcon />
                            </CopyToClipboard>
                        </HStack>
                    </BrowserView>

                    <MobileView>
                        <StatHelpText fontSize={'small'}>
                            <HStack>
                                <StatNumber>{totalERG} Σ</StatNumber>
                                <Stack direction='row'>
                                <Badge colorScheme='purple' rounded={'none'}>{totalTokens} tokens</Badge>
                                </Stack>
                            </HStack>
                            <HStack>
                            <Text>{truncateString(wallet, 20, '...')}</Text>
                            <CopyToClipboard text={wallet} onCopy={() => setInfoCopy(true)}>
                                <CopyIcon />
                            </CopyToClipboard>
                            </HStack>
                        </StatHelpText>
                    </MobileView>
                </Stat>
            </Box>
        </>
    )
}
