import React, { useEffect, useState } from 'react';
import { 
    Box,
    VStack,
    } from '@chakra-ui/react'
import { Header } from './Header';
import { Listado } from './Listado';

export function LeerWallet({wallet}){

    return(
        <> 
            <Box>
                <Header wallet={wallet}/>
                <Listado  wallet={wallet}/> 
            </Box>
        </>
    )
}
