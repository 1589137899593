import React from 'react';

import { Link } from 'wouter'

import { 
Button,
HStack,
Image,
Text,

    } from '@chakra-ui/react'

import LogotipoErgo from '../../assets/logoergo.png'

export const LogoErgo = props => {

  return (
    <Link href={'/'}>
        <Button rounded={'full'} variant={'ghost'}>
          <Image alt={'ErgoPlatform'} src={LogotipoErgo} width='25px' height={'25px'}/>
          <Text ml={1}>Ergo</Text>
        </Button> 
    </Link>
  );
};
