import React from 'react';
import {
  Box,
  Container,
  ChakraProvider,
  theme,
  VStack,
} from '@chakra-ui/react';
import { Header } from './components/header/Header';
import { Inicio } from './routes/Inicio';
import Footer from './components/footer/Footer';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container maxW='container.lg'>
          <Container minW={'100%'} p={4}>
            <Header />
          </Container>
          <Container minW={'100%'} p={3} >
            <Inicio />
          </Container>
          <Container minW={'100%'} p={3} >
            <Footer />
          </Container>  
      </Container>
    </ChakraProvider>
  );
}

export default App;
