import React from 'react'
import {
    Box,
    Center,
    HStack,
    Link,
    Text,
} from '@chakra-ui/react';

import { ModalDonate } from './ModalDonate';


export default function Footer (){
    return(
        <div
            style={{
                backgroundColor: '#1A202C',
                bottom: 0,
                left: 0,
                paddingLeft: '10px',
                position: "fixed",
                right: 0,
                color:'#fff'

            }}
            >
                <Center>
                    <Box fontSize={'xs'} mt={3}>
                        <HStack>
                            <ModalDonate/>
                            <Text>enjoy with {' '}
                                <Link color={'orange'}href='https://ergoplatform.org' isExternal textDecoration={'underline'}>ErgoPlatform </Link>
                                ♥ ErgoWallets.org
                            </Text>
                        </HStack>
                    </Box>
                </Center>
         </div>
    )
}