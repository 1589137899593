import React, {useEffect, useState} from 'react'
import { 
  Badge, 
  Box,
  Button, 
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack, 
  Text,
  useColorModeValue,
  useToast } from '@chakra-ui/react'

import { truncateString } from '../../functions/Functions'
import { Link, useLocation } from 'wouter'

export function Conector(){

    const bg = useColorModeValue('gray.200', 'gray.700')
    const color = useColorModeValue('cyan.500', 'cyan.300')

    const [location, setLocation] = useLocation();

    const toast = useToast()
    const id = 'test-toast'

    const NANOERG_TO_ERG = 1000000000
    const TOKENID_SIGRSV = '003bd19d0187117f130b62e1bcab0939929ff5c7709f843c5c4dd158949285d0'
    const TOKENID_SIGUSD = '03faf2cb329f2e90d6d23b58d91bbb6c046aa143261cc21f52fbe2824bfcbf04'
  
    const [ergoWallet, setErgoWallet] = useState()
    const [ergBalance, setErgBalance] = useState(0)
    const [sigUSDBalance, setSigUSDBalance] = useState(0)
    const [sigRSVBalance, setSigRSVBalance] = useState(0)
    const [ergBalanceHidden, setErgBalanceHidden] = useState(true)

    const [walletConnected, setWalletConnected] = useState(false)
    const [defaultAddress, setDefaultAddress] = useState('')
  
    const conectar = () => {
        arrancoConnector()
        connectNautilus()
    }
    const Desconectar = () => {
        disconnectWallet()
    }

    useEffect(() => {
        const checkWallet = localStorage.getItem("walletConnected")
        if (checkWallet === "true") {
        setDefaultAddress()
        window.ergoConnector.nautilus.connect().then((access_granted) => {
            if (access_granted) {
            setWalletConnected(true)
            window.ergoConnector.nautilus.getContext().then((context) => {
                setErgoWallet(context)
            })
            } else {
            setWalletConnected(false)
            }
        })
        setDefaultAddress(localStorage.getItem("walletAddress"))
        setWalletConnected(true)
        }
    }, [])




    useEffect(() => {
        if (typeof ergoWallet !== "undefined") {
        // get ERG balance
        ergoWallet.get_balance().then(function (balance) {
            // console.log(balance / NANOERG_TO_ERG)
            setErgBalance(balance / NANOERG_TO_ERG)
        })
        ergoWallet.get_balance(TOKENID_SIGUSD).then(function (balance) {
            setSigUSDBalance(balance / 100)
        })
        ergoWallet.get_balance(TOKENID_SIGRSV).then(function (balance) {
            setSigRSVBalance(balance / 100)
        })

        //get Address
        ergoWallet.get_change_address().then(function (address) {
            localStorage.setItem("walletAddress", address)
            // console.log(address)
            setDefaultAddress(address)
            setLocation(`/wallet/${address}`)
            localStorage.setItem("walletConnected", "true")
        })
        }
    }, [ergoWallet])

  
  
    function disconnectWallet() {
        if (typeof window.ergo_request_read_access === "undefined") {
        if (!toast.isActive(id)) {
            toast({ 
            id,
            description: "It is not possible to disconnect, you have no connector.",
            duration: 2000,
            isClosable: true,
            position: 'bottom',
            title: 'Error disconnecting Nautilus',
            status: 'error',
            variant: 'solid'
            })
        }
        } else {
        if (walletConnected) {
            setWalletConnected(false)
            setErgoWallet()
            setDefaultAddress('')
            setErgBalance(0.0)
            localStorage.removeItem("walletAddress")
            localStorage.removeItem("walletConnected")
            window.ergoConnector.nautilus.disconnect()
            if (!toast.isActive(id)) {
            toast({ 
                id,
                description: "Nautilus has been successfully disconnected.",
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                title: 'Your wallet has been disconnected',
                status: 'warning',
                variant: 'solid'
            })
            }
        setErgBalanceHidden(true)
        setLocation(`/`)
        }
        }
    }


    // Arranca el conector y sale cuando no estasmos conectados aun, 
    // si cancelamos la conexión muestra un mensaje y si la aceptamos
    // nos da el total disponible y la address
    function arrancoConnector(){

    
        const connectNautilus = () => {
        if (!window.ergoConnector) {
            return
        }
        window.ergoConnector.nautilus.isConnected().then((connected) => {
            if (!walletConnected) {
            window.ergoConnector.nautilus.connect().then((access_granted) => {
                if (access_granted) {
                setWalletConnected(true)
                window.ergoConnector.nautilus.getContext().then((context) => {
                    setErgoWallet(context)
                })
                if (!toast.isActive(id)) {
                    toast({ 
                    id,
                    description: "Your wallet is connected to Nautilus.",
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom',
                    title: 'Connected Wallet',
                    status: 'success',
                    variant: 'solid'
                    })
                }
                setErgBalanceHidden(false)
              } else {
                setWalletConnected(false)
                if (!toast.isActive(id)) {
                    toast({ 
                    id,
                    description: "You did not agree to connect to Nautilus.",
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom',
                    title: 'Connection not accepted',
                    status: 'error',
                    variant: 'solid'
                    })
                }
                setErgBalanceHidden(true)
                }
            })
            // toggleSelector()
            } else {
            // Already connected
            if (!toast.isActive(id)) {
                toast({ 
                id,
                description: "You already have your wallet connected to Nautilus.",
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                title: 'Connected Wallet',
                status: 'success',
                variant: 'solid'
                })
            }
            setErgBalanceHidden(false)
            return
            }
        })
        }
        connectNautilus()
    }


  const connectNautilus = () => {
    if (!window.ergoConnector) {
      if (!toast.isActive(id)) {
        toast({ 
            id,
            description: "You do not have a Nautilus connector.",
            duration: 2000,
            isClosable: true,
            position: 'bottom',
            title: 'Unable to connect',
            status: 'error',
            variant: 'solid'
        })
      }
      setErgBalanceHidden(true)
      return
    }
    
    window.ergoConnector.nautilus.isConnected().then((connected) => {
      if (!id) {
        window.ergoConnector.nautilus.connect().then((access_granted) => {
          if (access_granted) {
            setWalletConnected(true)
            window.ergoConnector.nautilus.getContext().then((context) => {
              setErgoWallet(context)
            })
          } else {
            setWalletConnected(false)
          }
        })
        // console.log('No te conectastes!')
      } else {
        // Already connected
        // console.log('Conectado a Nautilus')
        return
            }
    })

    if(walletConnected==true){
      setErgBalanceHidden(false)
    }
  }

  window.addEventListener("ergo_wallet_disconnected", () => {
    disconnectWallet()
  })

    return(
        <>  
        <Box>
          <Menu>
            <MenuButton as={Button} borderRadius={'full'}>
              dApp
            </MenuButton>
            <MenuList shadow={'2xl'}>
              <MenuItem onClick={conectar}>Connect</MenuItem>
              <MenuItem onClick={Desconectar}>Disconnect</MenuItem>
              <Divider mt={1} mb={2}/>
              <MenuItem >

              <Stack direction='row'>
                {/* <Badge color={color} hidden={ergBalanceHidden} >
                {ergBalance} Σ
                </Badge>
                <Badge color={color} hidden={ergBalanceHidden} >
                {sigUSDBalance} sigUSD
                </Badge>
                <Badge color={color} hidden={ergBalanceHidden} >
                {sigRSVBalance} sigRSV
                </Badge> */}
                <Link href={'/wallet/' + defaultAddress}>
                  <Text fontSize={'small'}>{truncateString(defaultAddress, 15, '...')}</Text>
                </Link>
                 </Stack>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        </>
    )
}



  

  